<template>
  <div>
    <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
      <li class="breadcrumb-item">
        <router-link :to="`/reputation/review-flows/`"> Surveys </router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <a href="#">Create survey</a>
      </li>
    </ol>

    <div class="row row-deck row-cards">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col">
                <label for="name" class="form-label">Survey Name</label>
                <input
                  :class="[
                    'form-control',
                    $v.flow.name.$error ? 'is-invalid' : '',
                  ]"
                  name="name"
                  id="name"
                  v-model="$v.flow.name.$model"
                />
                <div class="invalid-feedback" v-if="!$v.flow.name.required">
                  Survey name is required.
                </div>
              </div>
            </div>

            <select-template
              v-model="$v.flow.review_flow.$model"
            ></select-template>

            <div class="row mb-3">
              <div class="col">
                <label for="slug" class="form-label">Survey URL</label>
                <div
                  :class="[
                    'input-group',
                    'input-group-flat',
                    $v.flow.slug.$error ? 'is-invalid' : '',
                  ]"
                >
                  <span class="input-group-text">
                    {{ `${business_chatter_url}review/start/` }}
                  </span>
                  <input
                    type="text"
                    id="slug"
                    :class="[
                      'form-control',
                      'ps-0',
                      $v.flow.slug.$error ? 'is-invalid' : '',
                    ]"
                    v-model="$v.flow.slug.$model"
                    autocomplete="off"
                  />
                </div>
                <div class="invalid-feedback" v-if="!$v.flow.slug.required">
                  Survey URL is required.
                </div>
                <div class="invalid-feedback" v-if="!$v.flow.slug.url_friendly">
                  Survey URL can only contain the characters
                  <code>a-z</code>, <code>0-9</code>, and <code>_</code>.
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary"
              @click="create"
              :disabled="$v.$invalid"
              v-if="!saving"
            >
              Create Survey
            </button>
            <a
              href="#"
              :class="['btn', 'btn-primary', 'ms-auto', 'disabled']"
              @click.prevent
              v-else
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Saving
            </a>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">What is a survey?</h3>
          </div>

          <div class="card-body">
            <p>
              A <b>survey</b> is a collection of questions you can send to your
              customers to collect a reviews.
            </p>
            <p>
              Different <b>survey flows</b> will suit different companies. A
              survey flow is the set of questions a customer will answer when
              leaving your company a review.
            </p>
            <p>
              Customers will access your survey using the <b>survey URL</b> of
              your choice.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectTemplate from "./ReviewFlow/SelectTemplate.vue";
import { required, helpers } from "vuelidate/lib/validators";
import ReputationManagementService from "../../services/ReputationManagementService";

const url_friendly = helpers.regex("url_friendly", /^[a-z0-9-]+$/);

export default {
  components: { SelectTemplate },
  name: "reputation.create-review-flow",
  data: () => {
    return {
      saving: false,
      flow: {
        name: "",
        review_flow: null,
        slug: "",
      },
      business_chatter_url: ReputationManagementService.business_chatter_url,
    };
  },
  methods: {
    async create() {
      this.saving = true;
      try {
        let newFlow = await ReputationManagementService.addReviewFlow(
          this.$auth.activeCompany,
          this.flow
        );
        this.$router.push({ path: `/reputation/review-flow/${newFlow.id}` });
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
  },
  validations: {
    flow: {
      name: {
        required,
      },
      review_flow: {
        required,
      },
      slug: {
        required,
        url_friendly,
      },
    },
  },
};
</script>
