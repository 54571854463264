var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ol',{staticClass:"breadcrumb breadcrumb-arrows mb-3",attrs:{"aria-label":"breadcrumbs"}},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/reputation/review-flows/"}},[_vm._v(" Surveys ")])],1),_vm._m(0)]),_c('div',{staticClass:"row row-deck row-cards"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Survey Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.flow.name.$model),expression:"$v.flow.name.$model"}],class:[
                  'form-control',
                  _vm.$v.flow.name.$error ? 'is-invalid' : '' ],attrs:{"name":"name","id":"name"},domProps:{"value":(_vm.$v.flow.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.flow.name, "$model", $event.target.value)}}}),(!_vm.$v.flow.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Survey name is required. ")]):_vm._e()])]),_c('select-template',{model:{value:(_vm.$v.flow.review_flow.$model),callback:function ($$v) {_vm.$set(_vm.$v.flow.review_flow, "$model", $$v)},expression:"$v.flow.review_flow.$model"}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"slug"}},[_vm._v("Survey URL")]),_c('div',{class:[
                  'input-group',
                  'input-group-flat',
                  _vm.$v.flow.slug.$error ? 'is-invalid' : '' ]},[_c('span',{staticClass:"input-group-text"},[_vm._v(" "+_vm._s((_vm.business_chatter_url + "review/start/"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.flow.slug.$model),expression:"$v.flow.slug.$model"}],class:[
                    'form-control',
                    'ps-0',
                    _vm.$v.flow.slug.$error ? 'is-invalid' : '' ],attrs:{"type":"text","id":"slug","autocomplete":"off"},domProps:{"value":(_vm.$v.flow.slug.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.flow.slug, "$model", $event.target.value)}}})]),(!_vm.$v.flow.slug.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Survey URL is required. ")]):_vm._e(),(!_vm.$v.flow.slug.url_friendly)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Survey URL can only contain the characters "),_c('code',[_vm._v("a-z")]),_vm._v(", "),_c('code',[_vm._v("0-9")]),_vm._v(", and "),_c('code',[_vm._v("_")]),_vm._v(". ")]):_vm._e()])]),(!_vm.saving)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.$v.$invalid},on:{"click":_vm.create}},[_vm._v(" Create Survey ")]):_c('a',{class:['btn', 'btn-primary', 'ms-auto', 'disabled'],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status"}}),_vm._v(" Saving ")])],1)])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Create survey")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("What is a survey?")])]),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v(" A "),_c('b',[_vm._v("survey")]),_vm._v(" is a collection of questions you can send to your customers to collect a reviews. ")]),_c('p',[_vm._v(" Different "),_c('b',[_vm._v("survey flows")]),_vm._v(" will suit different companies. A survey flow is the set of questions a customer will answer when leaving your company a review. ")]),_c('p',[_vm._v(" Customers will access your survey using the "),_c('b',[_vm._v("survey URL")]),_vm._v(" of your choice. ")])])])])}]

export { render, staticRenderFns }