<template>
  <div class="mb-3">
    <label class="form-label">Select a survey flow</label>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <label class="form-label">Category</label>
            <ul>
              <li
                v-for="category in categories"
                :key="`category-${category.id}`"
              >
                <a href="#" @click.prevent="selectCategory(category)">
                  {{ category.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col" v-if="selectedCategory">
            <label class="form-label">
              {{ selectedCategory.name }} Surveys
            </label>
            <div
              class="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-1"
              v-for="flow in categoryFlows"
              :key="`flow-${flow.id}`"
            >
              <label class="form-selectgroup-item flex-fill">
                <input
                  type="radio"
                  name="flow"
                  :value="flow.id"
                  v-model="selectedFlow"
                  class="form-selectgroup-input"
                />
                <div
                  class="form-selectgroup-label d-flex align-items-center p-3"
                >
                  <div class="me-3">
                    <span class="form-selectgroup-check"></span>
                  </div>
                  <div
                    class="form-selectgroup-label-content d-flex align-items-center"
                  >
                    <div>
                      <div class="font-weight-medium">{{ flow.name }}</div>
                      <div class="text-muted">{{ flow.description }}</div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
export default {
  name: "reputation.review-flow.select-template",
  data: () => {
    return {
      categories: [],
      selectedCategory: null,
      categoryFlows: [],
      selectedFlow: null,
    };
  },
  watch: {
    selectedFlow() {
      this.$emit("input", this.selectedFlow);
    },
  },
  methods: {
    async selectCategory(category) {
      this.selectedCategory = category;
      this.categoryFlows =
        await ReputationManagementService.getReviewFlowCategoryFlows(
          this.$auth.activeCompany,
          this.selectedCategory.id
        );
    },
  },
  async mounted() {
    this.categories = await ReputationManagementService.getReviewFlowCategories(
      this.$auth.activeCompany
    );
    this.selectCategory(this.categories[0]);
  },
};
</script>
